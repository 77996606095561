import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import findRegistro from '../../service/findRegistro';
import { SortOrder } from 'primereact/api';
import { Button } from 'primereact/button';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Nullable } from 'primereact/ts-helpers';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge';
import "./styles.css";
import { Card } from 'primereact/card';
import { useGlobalState } from '../../components/globalState/GlobalStateProvider';



export default function RegistroList({onEdit, date}:{onEdit:(registro:any)=>void, date:Date}) {
  const { t } = useTranslation();
  const [registros, setRegistros] = useState(undefined);
  const [counts, setCounts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [nome, setNome] = useState<Nullable<string | null>>(null);
  const [codigoCliente, setCodigoCliente] = useState<Nullable<string | null>>(null);
  const [usuario, setUsuario] = useState<Nullable<string | null>>(null);
  const [documento, setDocumento] = useState<Nullable<string | null>>(null);
  const [data, setData] = useState<Nullable<Date>>(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [dataFinal, setDataFinal] = useState<Nullable<Date>>(new Date());
  const [status, setStatus] = useState<Nullable<String | null>>(null);
  const [descricaoErro, setDescricaoErro] = useState(undefined);
  const {state} = useGlobalState();
  
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, '0');
  }

  const formatDate = (date:Nullable<Date>) => {
    if(date){
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join('-');
    }
    else{
      return null;
    }
   
  }

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    nome: nome ? nome : null,
    documento: documento ? documento : null,
    status: status,
    loginUsuario: usuario ? usuario : null,
    codigoCliente: codigoCliente ? codigoCliente : null,
    sortField: 't.dataInclusao',
    sortOrder: SortOrder.DESC
  });

  const onPage = (event: any) => {
    setlazyState({
      first: event.first,
      rows: event.rows,
      page: (event.first / event.rows) + 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      nome: nome ? nome : null,
      documento: documento ? documento : null,
      status: status,
      loginUsuario: usuario ? usuario : null,
      codigoCliente: codigoCliente ? codigoCliente : null,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };
  const onSort = (event:any) => {
    setlazyState({
      first: 0,
      rows: 10,
      page: 1,
      data: formatDate(data),
      dataFinal: formatDate(dataFinal),
      nome: nome ? nome : null,
      documento: documento ? documento : null,
      status: status,
      loginUsuario: usuario ? usuario : null,
      codigoCliente: codigoCliente ? codigoCliente : null,
      sortField: event.sortField,
      sortOrder: event.sortOrder
    });
  };

  const changeData = (data:Nullable<Date>) => {
    if(data){
      setData(data);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome ? nome : null,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario ? usuario : null,
        codigoCliente: codigoCliente ? codigoCliente : null,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    }
  }

  const changeDataFinal = (dataFi:Nullable<Date>) => {
    if(dataFi){
      setDataFinal(dataFi);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFi),
        nome: nome ? nome : null,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario ? usuario : null,
        codigoCliente: codigoCliente ? codigoCliente : null,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    }
  }
  

  const changeNome = (nome:Nullable<string>) => {
    nome = nome ? nome : null
      setNome(nome);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario ? usuario : null,
        codigoCliente: codigoCliente ? codigoCliente : null,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeCodigoCliente = (codigoCliente:Nullable<string>) => {
    codigoCliente = codigoCliente ? codigoCliente : null
      setCodigoCliente(codigoCliente);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome ? nome : null,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario ? usuario : null,
        codigoCliente: codigoCliente,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeUsuario = (usuario:Nullable<string>) => {
    usuario = usuario ? usuario : null
      setUsuario(usuario);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome ? nome : null,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario,
        codigoCliente: codigoCliente ? codigoCliente : null,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeStatus = (status:Nullable<any>) => {
    status = status ? status : null
    if(status instanceof Object){
      status = null
    }
    console.log(status)
      setStatus(status);
      setlazyState({
        first: lazyState.first,
        rows: lazyState.rows,
        page: (lazyState.first / lazyState.rows) + 1,
        data: formatDate(data),
        dataFinal: formatDate(dataFinal),
        nome: nome ? nome : null,
        documento: documento ? documento : null,
        status: status,
        loginUsuario: usuario ? usuario : null,
        codigoCliente: codigoCliente ? codigoCliente : null,
        sortField: 't.dataInclusao',
        sortOrder: SortOrder.DESC
      });
    
  }

  const changeDocumento = (doc:Nullable<string>) => {
    doc = doc ? doc.replaceAll('.','').replaceAll('-','').replaceAll('/','') : null
       setDocumento(doc);
       setlazyState({
         first: lazyState.first,
         rows: lazyState.rows,
         page: (lazyState.first / lazyState.rows) + 1,
         data: formatDate(data),
         dataFinal: formatDate(dataFinal),
         nome: nome ? nome : null,
          documento: doc,
          status: status,
          loginUsuario: usuario ? usuario : null,
          codigoCliente: codigoCliente ? codigoCliente : null,
         sortField: 't.dataInclusao',
         sortOrder: SortOrder.DESC
       });
     
   }

   const clearFields = () => {
    setStatus(null);
    setNome('');
    setUsuario('');
    setDocumento('');
    setData(null)
    setDataFinal(null)
    
    setlazyState({
      first: lazyState.first,
      rows: lazyState.rows,
      page: (lazyState.first / lazyState.rows) + 1,
      data: formatDate(null),
      dataFinal: formatDate(null),
      nome: nome ? nome : null,
      documento: documento ? documento : null,
      status: status,
      loginUsuario: usuario ? usuario : null,
      codigoCliente: codigoCliente ?  codigoCliente : null,
      sortField: 't.dataInclusao',
      sortOrder: SortOrder.DESC
    });
  };

   const cnpj = (v:string) =>{
    v=v.replace(/\D/g,"")                           //Remove tudo o que não é dígito
    v=v.replace(/^(\d{2})(\d)/,"$1.$2")             //Coloca ponto entre o segundo e o terceiro dígitos
    v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3") //Coloca ponto entre o quinto e o sexto dígitos
    v=v.replace(/\.(\d{3})(\d)/,".$1/$2")           //Coloca uma barra entre o oitavo e o nono dígitos
    v=v.replace(/(\d{4})(\d)/,"$1-$2")              //Coloca um hífen depois do bloco de quatro dígitos
    return v
}

const cpf = (v:string) => {
    v=v.replace(/\D/g,"")                    //Remove tudo o que não é dígito
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
    v=v.replace(/(\d{3})(\d)/,"$1.$2")       //Coloca um ponto entre o terceiro e o quarto dígitos
                                             //de novo (para o segundo bloco de números)
    v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2") //Coloca um hífen entre o terceiro e o quarto dígitos
    return v
}

const maskCpfCnpj = (input:string) =>{
 console.log(input)
  if(input.length > 14){
    return cnpj(input)
  }
  else{
    return cpf(input)
  }
}

const cols = [
  { field: 'cliente', header: 'Cliente' },
  { field: 'documento', header: 'Documento' },
  { field: 'nome', header: 'Nome' },
  { field: 'contrato', header: 'Contrato' },
  { field: 'valor', header: 'Valor' },
  { field: 'ocorrencia', header: 'Dt. Ocorrência' },
  { field: 'dataInclusao', header: 'Dt. Inclusão' },
  { field: 'email', header: 'E-mail' },
  { field: 'sms', header: 'SMS' },
  { field: 'status', header: 'Status' }
];
const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
const exportPdf = () => {
  import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
          const lazy = {
            first: 0,
            rows: totalRecords,
            page: 1,
            data: formatDate(data),
            dataFinal: formatDate(dataFinal),
            nome: nome ? nome : null,
            documento: documento ? documento : null,
            status: status,
            loginUsuario: usuario ? usuario : null,
            codigoCliente: codigoCliente ? codigoCliente : null,
            sortField: 'dataInclusao',
            sortOrder: SortOrder.DESC
          }
          findRegistro(lazy).then(data => {
            const doc : any = new jsPDF.default('portrait');
            doc.autoTable(exportColumns, data.registros);
            doc.save('registros.pdf');
          });
          
      });
  });
};

const exportExcel = () => {
import('xlsx').then((xlsx) => {
  const lazy = {
    first: 0,
    rows: totalRecords,
    page: 1,
    data: formatDate(data),
    dataFinal: formatDate(dataFinal),
    nome: nome ? nome : null,
    documento: documento ? documento : null,
    status: status,
    loginUsuario: usuario ? usuario : null,
    codigoCliente: codigoCliente ? codigoCliente : null,
    sortField: 'dataInclusao',
    sortOrder: SortOrder.DESC
  }
  findRegistro(lazy).then(data => {
    const worksheet = xlsx.utils.json_to_sheet(data.registros);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
    });
    saveAsExcelFile(excelBuffer, 'registros');
  });

    
});
};

const handleDocumento = (event:any) => {
event.target.value =  maskCpfCnpj(event.target.value)
}

const saveAsExcelFile = (excelBuffer: any, fileName: string) => {
import('file-saver').then((module) => {
    if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([excelBuffer], {
            type: EXCEL_TYPE
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    }
});
};
const header = (
  <div className="flex-consulta align-items-center justify-content-end gap-2">
      <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
      <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />
  </div>
);

  const startContent = (
    <React.Fragment>
      <div  style={{background:'transparent'}}>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="data" className="font-bold block mb-2">{t('dataInicial')} </label>
                  <Calendar id='data' value={data} onChange={(e) => changeData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div>
                <div className="flex-auto">
                  <label htmlFor="documento" className="font-bold block mb-2">{t('documento')} </label>
                  <InputText id="documento" value={documento} onChange={(e) => setDocumento(e.target.value)} maxLength={18} max={18} onKeyUp={handleDocumento} onBlur={(e)=>changeDocumento(e.target.value)} style={{width: '20rem'}}></InputText>
                </div>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto">
                        <label htmlFor="usuario" className="font-bold block mb-2">{t('usuario')} </label>
                        <InputText id="usuario" value={usuario} onChange={(e) => setUsuario(e.target.value) } onBlur={(e)=>changeUsuario(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
        <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                <div>
                <div >
                    <div className="flex-auto" style={{visibility:'hidden'}}>
                    <label htmlFor="text" className="font-bold block mb-2">{t('nome')} </label>
                    <InputText id="text"  style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
              </div>
        </div>
      </div>
      <div style={{background:'transparent', marginLeft:'10rem'}}>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                  <label htmlFor="dataFinal" className="font-bold block mb-2">{t('dataFinal')} </label>
                  <Calendar id='dataFinal' value={dataFinal} onChange={(e) => changeDataFinal(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto">
                        <label htmlFor="nome" className="font-bold block mb-2">{t('nome')} </label>
                        <InputText id="nome" value={nome} onChange={(e) => setNome(e.target.value)}  onBlur={(e)=>changeNome(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
        <div className="grid">
              
              <div className="col p-fluid flex flex-wrap">
                <div >
                    <div className="flex-auto" style={{visibility : (state.user.cliente ? 'hidden' : 'visible')}}>
                        <label htmlFor="codigoCliente" className="font-bold block mb-2">{t('codigoCliente')} </label>
                        <InputText id="codigoCliente" value={codigoCliente} onChange={(e) => setCodigoCliente(e.target.value) } onBlur={(e)=>changeCodigoCliente(e.target.value)} style={{width: '20rem'}} required></InputText>
                    </div>
                </div>
              </div>
        </div>
        <Button label='Limpar' icon="pi pi-times" onClick={clearFields} className="button_remove_backgroung" outlined style={{ height: '3rem', marginTop: '1.8rem' }} />
      </div>
      
      
    </React.Fragment>
  );

  const loadLazyData = () => {
    findRegistro(lazyState).then(data => {setTotalRecords(data.total);setCounts(data.status);setRegistros(data.registros)}).catch(() => {setTotalRecords(0);setRegistros(undefined);setCounts([])});
  }

  const actionTemplate = (event:any) => {
    return (
        <div className="flex flex-wrap gap-2" style={{display:'flex', justifyContent:'end'}}>

            {event.status === 'Erro' &&  (<Button icon="pi pi-search" onClick={() => {setDescricaoErro(event.descricaoErro)}} style={{background:'transparent', color:'gray', border: 'none'}} />)}
            <Button icon="pi pi-eraser" onClick={() => onEdit(event)} style={{background:'transparent', color:'gray', border: 'none'}} />
        </div>
    );
  };

  const headerCard = (item:any) => {return (
    <> 
     <div style={{display:"flex", justifyContent:"flex-end"}} className="flex-consulta">
        {status && status === item.status && (<Button icon="pi pi-times" className='button_remove_backgroung' onClick={(e) => {e.stopPropagation(); changeStatus(null)}} text style={{color:'rgb(24, 52, 98)'}}  aria-label="Cancel" />)}
     </div> </>
  )};

  const footerCard = (item:any) => {return (
    <> 
     <div style={{display:"flex-consulta", justifyContent:"flex-end"}} className="flex-consulta">
        <span style={{color: 'rgb(24, 52, 98)'}} className="font-bold text-md">R$ {item.valorTotal}</span>
     </div> </>
  )};

  const statusTemplate = (registro:any) => {
    const statusClass = registro.status === 'Erro' ? 'status-badge-erro' : (registro.status === 'Incluído' ? 'status-badge-incluido' : (registro.status === 'Registrado' ? 'status-badge-registrado' : (registro.status === 'Excluído' ? 'status-badge-excluido' : (registro.status == 'Cancelado' ? 'status-badge-cancelado' : 'status-badge-total'))))
    return <><Badge value={registro.status} className={statusClass}></Badge></>
  }

  const resetForm = () => {
    setDescricaoErro(undefined);
  }  

  const footerContent = (
    <React.Fragment>
          <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => resetForm()} className="p-button-text" />
    </React.Fragment>
  );
  
  useEffect(() => {
    loadLazyData();
  },[lazyState, date])

  return (
    <div>
      <div className="card">
        <Toolbar start={startContent} />
        <br></br>
        <div className="flex-consulta  flex-wrap gap-3">
            {counts.map((item:any, index:number) => (
               
               <Card footer={footerCard(item)} style={{cursor:'pointer'}} className={(status && status !== item.status ? 'flex-1 disabled' : 'flex-1') + (status && status === item.status ? ' show-hide' : '') } header={headerCard(item)} key={index} onClick={() => changeStatus(item.status !== 'undefined' ? item.status : null)}>
                      <div className="flex-consulta  justify-content-between gap-5">
                          <div className="flex-consulta  flex-column gap-1">
                              <span className="text-secondary text-sm">{statusTemplate({status:item.statusLabel})}</span>
                              <span className="font-bold text-lg">{item.total}</span>
                          </div>
                          <span className="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" style={{ backgroundColor: item.status === 'ERRO' ? 'var(--red-500)' : (item.status === 'INCLUIDO' ? 'var(--blue-500)' : (item.status === 'REGISTRADO' ? 'var(--green-500)' : (item.status === 'EXCLUIDO' ? 'var(--orange-500)' : (item.status === 'CANCELADO' ? 'var(--yellow-500)' : 'var(--teal-500)')))), color: '#ffffff' }}>
                              <i className={item.status === 'ERRO' ? 'pi pi-exclamation-triangle' : (item.status === 'EXCLUIDO' ? 'pi pi-times' : (item.status === 'CANCELADO' ? 'pi pi-times-circle' : (item.status === 'INCLUIDO' ? 'pi pi-file-plus' : (item.status === 'REGISTRADO' ? 'pi pi-folder-plus' : 'pi pi-gauge'))) ) } />
                          </span>
                      </div>
                  </Card>
                
            ))}
        </div>
         <br></br>
        <DataTable value={registros} first={lazyState.first} dataKey="id" paginator={true}  lazy={true}
                        emptyMessage="Sem registros" header={header}
                        onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                        rows={lazyState.rows} totalRecords={totalRecords} onPage={onPage}
                        tableStyle={{ minWidth: '75rem' }}>
           <Column field="cliente" header={t('cliente')}  />
           <Column field="documento" sortable  header={t('documento')}  />
           <Column field="nome" sortable  header={t('nome')}  />
           <Column field="contrato"  sortable header={t('contrato')}  />
           <Column field="valor" sortable  header={t('valor')}  />
           <Column field="ocorrencia" sortable  header={t('dtOcorrencia')}  />
           <Column field="dataInclusao" sortable  header={t('dtInclusao')}  />
           <Column field="email"  header={t('email')}  />
           <Column field="sms"  header={t('sms')}  />
           <Column field="tipoComunicado" sortable sortField='tipoComunicacao'  header={t('tipoComunicado')}  />
           <Column field="dataComunicado" sortable sortField='dataComunicacao'  header={t('dataComunicado')}  />
           <Column body={statusTemplate} sortable sortField='status' header={t('status')} />
           <Column body={actionTemplate} />
           
        </DataTable> 
      </div>
      <Dialog header={t('erros')} visible={descricaoErro} style={{ width: '80vw' }} onHide={() => resetForm()} footer={footerContent}>
        <p>{descricaoErro}</p>
      </Dialog>
    </div>
  )
}


